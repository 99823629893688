import React  from 'react';

import Link from './Link';

import { SList } from "./styles";

const LinksList = ({ links }) => (
    <SList>
        {
            links.map( linkData => (
                <Link
                    linkData={ linkData }
                    key={ linkData.id }
                />
            ))
        }
    </SList>
);

export default LinksList;
