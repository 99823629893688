import React, { useState, useEffect, useContext } from 'react';

import { SectionAppearanceContext } from '../../sections/SectionContext';

import { checkAppearanceInViewport } from '../../../utils/checkAppearanceInViewport';

import {
    SLink
} from './styles';

const Link = ({ linkData }) => {
    const {
        name,
        action,
        relatedSection
    } = linkData;

    const [ activeSection, setActiveSection ] = useContext(SectionAppearanceContext);
    const [ isLinkActive, setIsLinkActive ] = useState(false);

    const handleLinkAction = () => action();

    const handleSetCurrentSection = sectionId => {
        if (sectionId !== activeSection) {
            setActiveSection(sectionId);
        }
    };

    useEffect(() => {
        checkAppearanceInViewport(
            relatedSection,
            handleSetCurrentSection
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relatedSection]);

    useEffect(() => {
        setIsLinkActive(relatedSection.current.id === activeSection);
    }, [relatedSection, activeSection]);

    return (
        <SLink
            onClick={ handleLinkAction }
            isActive={ isLinkActive }
        >
            { name }
        </SLink>
    );
};

export default Link;
