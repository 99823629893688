import styled from 'styled-components';
import { colors, breakpoints } from '../../../styles/variables';

import { SSection, SSectionContent } from '../../layout/Section/styles';


export const NavbarSection = styled(SSection)`
    top: 0;
    z-index: 10;

    padding: ${
        ({ isPopupNavbar }) => isPopupNavbar ? '2.4rem' : '3.2rem 3rem'
    };
    
    position: ${
        ({ isPopupNavbar }) => isPopupNavbar ? 'relative' : 'absolute'
    };

    margin-bottom: ${
        ({ isPopupNavbar }) => isPopupNavbar ? '-3.2rem' : '0'
    };

    background-color: ${
        ({ isSticky }) => isSticky ? colors.white : 'none'
    };

    @media (min-width: ${ breakpoints.popupMedium }) {
        padding: ${
            ({ isPopupNavbar }) => isPopupNavbar ? '3.2rem' : '3.2rem 3rem'
        };
    };

    @media (min-width: ${ breakpoints.medium }) {
        position: ${
            ({ isSticky, isPopupNavbar }) => {
                const stickyPosition = isSticky ? 'fixed' : 'absolute';
                return isPopupNavbar ? 'relative' : stickyPosition;
            }
        };
    };
`;

export const NavbarSectionContent = styled(SSectionContent)`
    flex-direction: row;
    justify-content: space-between;
`;

export const LogoBox = styled.div`
    width: ${
        ({ customWidth }) => customWidth || '143px'
    };
    height: auto;
`;

export const SList = styled.ul`
    display: none;
    list-style: none;
    margin: 0;

    @media (min-width: ${ breakpoints.medium }) {
        display: flex;
    }
`;

export const SLink = styled.li`
    padding: .8rem 1.6rem;
    font-size: 1.4rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    margin: 0;
    cursor: pointer;
    color: ${ ({ isActive }) => isActive ? colors.paypoGreen600 : colors.black };
    transition: .2s;

    &:hover {
        color: ${ colors.paypoGreen500 };
    }
`;
