import React from 'react';

import { colors } from '../../styles/variables';

import CookieModal from '../../components/organisms/CookieModal';
import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import Navbar from '../../components/organisms/Navbar';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionVideoContainer,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    START_NAVBAR_CONFIG,
    START_VIDEO_CONTAINER_CONFIG,
    START_ICON_ITEMS_CONFIG,
    START_ACCORDION_SECTION,
    START_INLINE_TILES_CONFIG,
    START_BIG_TILES_CONFIG,
} from '../../content_config_start';

import {
    START_PSP_HERO_CONFIG,
    START_PSP_ORDERED_ITEMS_CONFIG,
} from '../../content_config_start_psp';

const IndexPSP = () => (
    <div className="page-body sticky-navbar">
        <SectionAppearanceProvider>
            <Seo title="Start PayPo.pl" />

            <Navbar
                config={ START_NAVBAR_CONFIG }
                sticky
            />

            <SectionHeroStart
                config={ START_PSP_HERO_CONFIG }
            />

            <SectionOrderedItems
                config={ START_PSP_ORDERED_ITEMS_CONFIG }
                columns={ 3 }
                itemsReversed
            />

            <SectionVideoContainer
                config={ START_VIDEO_CONTAINER_CONFIG }
                backgroundBottom={ colors.grayCultured }
            />

            <SectionIconItems
                config={ START_ICON_ITEMS_CONFIG }
                background={ colors.grayCultured }
            />

            <SectionAccordion
                config={ START_ACCORDION_SECTION }
                isColorSectionAbove={ true }
            />

            <SectionInlineTiles
                config={ START_INLINE_TILES_CONFIG }
            />

            <SectionBigTiles
                config={ START_BIG_TILES_CONFIG }
            />

            <CookieModal />
        </SectionAppearanceProvider>
    </div>
);

export default IndexPSP;
