import React from 'react';

import { LogoPayPo } from '../../../assets/images';

import LinksList from './LinksList';

import {
    NavbarSection,
    NavbarSectionContent,
    LogoBox,
} from './styles';

const Navbar = ({
    config = {},
    sticky = false,
    popupNavbar = false,
}) => {
    const { navigationLinks, customLogo } = config;

    return (
        <NavbarSection
            isSticky={ sticky }
            isPopupNavbar={ popupNavbar }
        >
            <NavbarSectionContent>
                <LogoBox customWidth={ customLogo && customLogo.width }>
                    {
                        customLogo && customLogo.logo || <LogoPayPo />
                    }
                </LogoBox>
                {
                    navigationLinks &&
                    <LinksList links={ navigationLinks } />
                }
            </NavbarSectionContent>
        </NavbarSection>
    );
};

export default Navbar;
