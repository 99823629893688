import { colors } from './styles/variables';

import {
    heroRef,
    howItWorksRef,
} from './utils/sectionRefs';

export const START_PSP_HERO_CONFIG = {
    title: "Kup teraz, zapłać za 30 dni lub później",
    id: "section-hero",
    forwardedRef: heroRef.forwardedRef,
    navigationRef: heroRef.navigationRef,
    subtitle: "Z PayPo możesz też płacić w wygodnych ratach",
    content: [
        {
            text: "Zamawiasz jak zwykle w sklepach obsługiwanych przez PayPo. Odbierasz zakupy, sprawdzasz, czy Ci pasują, a płacisz nawet 30 dni po złożeniu zamówienia. Bez żadnych kosztów i rejestracji. Jeśli potrzebujesz więcej czasu - rozkładasz płatność na wygodne raty.",
        },
    ],
    heroGraphic: "psp",
};

export const START_PSP_ORDERED_ITEMS_CONFIG = {
    title: "Jak to działa?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "Krok 1",
            text: "Robiąc zakupy online, dodajesz produkty do koszyka i wybierasz PayPo jako metodę płatności.",
        },
        {
            id: 2,
            title: "Krok 2",
            text: "Jeżeli nie widzisz nas na etapie wyboru płatności - nic straconego. Znajdziesz PayPo po wyborze bramki płatniczej na liście banków.",
        },
        {
            id: 3,
            title: "Krok 3",
            text: "Jeśli nie masz jeszcze konta w PayPo, to podajesz swoje dane. Przy kolejnych zakupach potwierdzasz transakcję wyłącznie kodem SMS.",
        },
        {
            id: 4,
            title: "Krok 4",
            text: "PayPo opłaca Twoje zakupy internetowe w sklepie, a Ty otrzymujesz swoje zamówienie.",
        },
        {
            id: 5,
            title: "Krok 5",
            text: "Sprawdzasz zakupy w domu, a na spłatę należności dla PayPo masz aż 30 dni. Pamiętaj, że płacisz tylko za te produkty, które zatrzymasz.",
        },
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        }
    ],
};
